import React from 'react';
import { Container, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { ClerkProvider, SignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import TimeTracker from './components/TimeTracker';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error('Missing Publishable Key');
}

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <SignedIn>
          <TimeTracker />
        </SignedIn>
        <SignedOut>
          <Container maxWidth="sm" sx={{ textAlign: 'center', py: 8 }}>
            <SignIn />
          </Container>
        </SignedOut>
      </ThemeProvider>
    </ClerkProvider>
  );
}

export default App; 