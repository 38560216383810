import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import {
  Box,
  Button,
  TextField,
  Paper,
  Typography,
  IconButton,
  Grid,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  InputBase,
  Tooltip as MuiTooltip,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  Autocomplete,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Drawer,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';
import { 
  Delete, 
  Add, 
  AccountCircle,
  CalendarToday,
  AccessTime,
  Description,
  FolderOutlined,
  Search,
  SupervisorAccount,
  Dashboard,
  AttachMoney,
  Check,
  Close,
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, startOfWeek, endOfWeek, addDays, isToday, startOfDay, endOfDay, startOfMonth, endOfMonth, startOfYear, endOfYear, eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval, parseISO } from 'date-fns';
import { useUser, useClerk } from '@clerk/clerk-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY,
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: true
    },
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }
);

// Custom tooltip component
const CustomTooltip = ({ active, payload, label, isAdmin }) => {
  if (active && payload && payload.length > 0) {
    const nonZeroEntries = payload.filter(entry => entry.value > 0);
    const hourlyRate = payload[0]?.payload?.hourlyRate;
    const revenue = payload[0]?.payload?.revenue;
    
    return (
      <Box
        sx={{
          backgroundColor: '#1a1a1a',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '8px',
          p: 1.5,
        }}
      >
        <Typography sx={{ mb: 1, fontWeight: 600 }}>{label}</Typography>
        {nonZeroEntries.map((entry, index) => (
          entry.dataKey === 'total' ? (
            <Box 
              key={`item-${index}`}
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 0.5,
                color: entry.color,
              }}
            >
              <Typography variant="body2">
                Hours: {entry.value.toFixed(1)}
              </Typography>
            </Box>
          ) : entry.dataKey === 'revenue' && isAdmin ? (
            <Box 
              key={`item-${index}`}
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 0.5,
                color: '#4caf50',
              }}
            >
              <Typography variant="body2">
                Revenue: ${entry.value.toFixed(2)}
              </Typography>
            </Box>
          ) : null
        ))}
      </Box>
    );
  }
  return null;
};

// Add this helper function to calculate total hours
const calculateTotalHours = (data) => {
  if (!data || data.length === 0) return 0;
  return data.reduce((total, day) => total + (day.total || 0), 0);
};

// Add this new component for success animation
const SuccessAnimation = ({ onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(onComplete, 2000); // Duration of success animation
    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '5%',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 2000,
        padding: '12px 24px',
        borderRadius: '12px',
        backgroundColor: 'rgba(21, 128, 61, 0.9)',
        backdropFilter: 'blur(8px)',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        animation: 'slideDown 0.3s ease-out, fadeOut 0.3s ease-in 1.7s',
        '@keyframes slideDown': {
          from: {
            transform: 'translate(-50%, -100%)',
            opacity: 0,
          },
          to: {
            transform: 'translate(-50%, 0)',
            opacity: 1,
          },
        },
        '@keyframes fadeOut': {
          to: {
            opacity: 0,
          },
        },
      }}
    >
      <Box
        component="span"
        sx={{
          width: 20,
          height: 20,
          borderRadius: '50%',
          border: '2px solid #fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            width: 6,
            height: 10,
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-1px, -1px)',
          },
        }}
      />
      <Typography sx={{ color: '#fff', fontWeight: 500 }}>
        Time entry added successfully
      </Typography>
    </Box>
  );
};

// Update the UserMenu component to be minimal and fix syntax
const UserMenu = ({ user, signOut }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // Generate a consistent but random avatar for each user
  const avatarUrl = `https://api.dicebear.com/7.x/bottts/svg?seed=${user.emailAddresses[0].emailAddress}&backgroundColor=1976d2`;

  return (
    <Box>
      <MuiTooltip title={user.firstName}>
        <Avatar
          src={avatarUrl}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ 
            width: 36,
            height: 36,
            cursor: 'pointer',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            border: '2px solid rgba(255, 255, 255, 0.1)',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.5)',
            }
          }}
        />
      </MuiTooltip>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          sx: {
            mt: 1,
            minWidth: 120,
            backgroundColor: '#1a1a1a',
            borderRadius: '12px',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          }
        }}
      >
        <MenuItem 
          onClick={() => {
            signOut();
            setAnchorEl(null);
          }}
          sx={{ 
            py: 1.5,
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }
          }}
        >
          Sign out
        </MenuItem>
      </Menu>
    </Box>
  );
};

// Update the NavigationDrawer component
const NavigationDrawer = ({ selectedView, setSelectedView }) => {
  const navItems = [
    { id: 'dashboard', label: 'Dashboard', icon: <Dashboard /> },
    { id: 'projects', label: 'Projects', icon: <FolderOutlined /> },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRight: '1px solid rgba(255, 255, 255, 0.1)',
          height: '100vh',
          position: 'fixed',
          top: 0,
        },
      }}
    >
      <Box sx={{ p: 3, borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Ontora
        </Typography>
      </Box>
      <List sx={{ mt: 2 }}>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              selected={selectedView === item.id}
              onClick={() => setSelectedView(item.id)}
              sx={{
                mx: 2,
                borderRadius: '8px',
                '&.Mui-selected': {
                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.15)',
                  },
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                },
              }}
            >
              <ListItemIcon sx={{ 
                minWidth: 40,
                color: selectedView === item.id ? '#1976d2' : 'inherit'
              }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.label}
                primaryTypographyProps={{
                  sx: { 
                    fontWeight: selectedView === item.id ? 600 : 400,
                    color: selectedView === item.id ? '#1976d2' : 'inherit'
                  }
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

// Update the syncUserToDatabase function
const syncUserToDatabase = async (user) => {
  if (!user) return;

  try {
    const email = user.emailAddresses[0].emailAddress;
    const name = `${user.firstName || ''} ${user.lastName || ''}`.trim();

    // First check if user exists
    const { data: existingUser, error: fetchError } = await supabase
      .from('app_users')
      .select('id, email, is_admin')
      .eq('email', email)
      .maybeSingle();

    if (fetchError) {
      console.error('Error checking existing user:', fetchError);
      return;
    }

    // Prepare user data
    const userData = {
      email,
      name,
      is_admin: email === 'reid@linkt.ai' ? true : (existingUser?.is_admin || false),
      updated_at: new Date().toISOString()
    };

    // If user exists, include their ID
    if (existingUser?.id) {
      userData.id = existingUser.id;
    }

    // Upsert user data
    const { error: upsertError } = await supabase
      .from('app_users')
      .upsert(userData, {
        onConflict: 'email',
        returning: 'minimal'
      });

    if (upsertError) {
      console.error('Error upserting user:', upsertError);
      return;
    }

    console.log('User synced successfully:', userData);
  } catch (err) {
    console.error('Error in syncUserToDatabase:', err);
  }
};

function TimeTracker() {
  const { user } = useUser();
  const { signOut } = useClerk();
  const [isAdmin, setIsAdmin] = useState(false);
  const [entries, setEntries] = useState([]);
  const [timeRange, setTimeRange] = useState('week');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [newEntry, setNewEntry] = useState({
    hours: '',
    minutes: '',
    taskDescription: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [jiraProjects, setJiraProjects] = useState([]);
  const [jiraTickets, setJiraTickets] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [selectedJiraProject, setSelectedJiraProject] = useState(null);
  const [selectedJiraTicket, setSelectedJiraTicket] = useState(null);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [adminMode, setAdminMode] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedView, setSelectedView] = useState('dashboard');
  const [projectUsers, setProjectUsers] = useState({});
  const [showProjectAccessModal, setShowProjectAccessModal] = useState(false);
  const [selectedProjectForAccess, setSelectedProjectForAccess] = useState(null);
  const [selectedUserForAccess, setSelectedUserForAccess] = useState(null);
  const [accessibleProjects, setAccessibleProjects] = useState([]);
  const [projectRates, setProjectRates] = useState({});

  // Move checkAdminStatus inside the component
  const checkAdminStatus = async () => {
    if (!user) return false;
    
    const userEmail = user.emailAddresses[0].emailAddress;
    
    try {
      const { data, error } = await supabase
        .from('app_users')
        .select('is_admin')
        .eq('email', userEmail)
        .single();

      if (error) {
        console.error('Error checking admin status:', error);
        return false;
      }

      const isUserAdmin = data?.is_admin || false;
      console.log('Admin check result:', { userEmail, isAdmin: isUserAdmin });
      setIsAdmin(isUserAdmin);
      return isUserAdmin;
    } catch (err) {
      console.error('Error in checkAdminStatus:', err);
      return false;
    }
  };

  // Update initialization useEffect
  useEffect(() => {
    const initialize = async () => {
      if (!user) return;

      try {
        const userEmail = user.emailAddresses[0].emailAddress;

        // First sync user to database
        await syncUserToDatabase(user);

        // Then check admin status
        const adminStatus = await checkAdminStatus();
        console.log('Admin status set to:', adminStatus);

        // Fetch entries based on admin status
        if (adminStatus) {
          const { data: allEntries, error: entriesError } = await supabase
            .from('time_entries')
            .select('*')
            .order('date', { ascending: false });

          if (entriesError) throw entriesError;
          setEntries(allEntries.map(entry => ({
            ...entry,
            date: new Date(entry.date)
          })));
        } else {
          // Non-admin only sees their entries
          const { data: userEntries, error: entriesError } = await supabase
            .from('time_entries')
            .select('*')
            .eq('user_email', userEmail)
            .order('date', { ascending: false });

          if (entriesError) throw entriesError;
          setEntries(userEntries.map(entry => ({
            ...entry,
            date: new Date(entry.date)
          })));

          // Also fetch accessible projects for non-admin users
          const accessibleProjectKeys = await filterAccessibleProjects(userEmail);
          console.log('Accessible project keys:', accessibleProjectKeys);
          
          // Store accessible project keys in state for later use
          setAccessibleProjects(accessibleProjectKeys);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error in initialization:', error);
        setError('Failed to initialize app');
        setLoading(false);
      }
    };

    initialize();
  }, [user]);

  const fetchJiraProjects = async () => {
    if (!user) return;
    const userEmail = user.emailAddresses[0].emailAddress;

    try {
      if (!isAdmin) {
        // Fetch fresh accessible projects
        const accessibleProjectKeys = await filterAccessibleProjects(userEmail);
        
        if (accessibleProjectKeys.length === 0) {
          console.log('User has no accessible projects');
          setJiraProjects([]);
          return;
        }

        const { data, error } = await supabase
          .from('jira_projects')
          .select('*')
          .in('key', accessibleProjectKeys)
          .order('name');
      
        if (error) throw error;
        console.log('Fetched accessible projects:', data);
        setJiraProjects(data);
      } else {
        const { data, error } = await supabase
          .from('jira_projects')
          .select('*')
          .order('name');
      
        if (error) throw error;
        setJiraProjects(data);
      }
    } catch (error) {
      console.error('Error fetching Jira projects:', error);
      setError('Failed to load Jira projects');
    }
  };

  const fetchJiraTickets = async (projectKey) => {
    if (!projectKey) return;
    
    setLoadingTickets(true);
    try {
      const { data, error } = await supabase
        .from('jira_tickets')
        .select('*')
        .eq('project_key', projectKey)
        .order('updated_at', { ascending: false });
      
      if (error) throw error;
      setJiraTickets(data);
    } catch (error) {
      console.error('Error fetching Jira tickets:', error);
      setError('Failed to load Jira tickets');
    } finally {
      setLoadingTickets(false);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut();
    handleClose();
  };

  const addTimeEntry = async () => {
    if (!selectedJiraTicket || (!newEntry.hours && !newEntry.minutes)) {
      setError('Please fill in all required fields');
      return;
    }

    const totalMinutes = (parseInt(newEntry.hours || 0) * 60) + parseInt(newEntry.minutes || 0);
    
    const entry = {
      task: `${selectedJiraTicket.key} - ${selectedJiraTicket.summary}`,
      task_description: newEntry.taskDescription || '',
      date: selectedDate,
      duration: totalMinutes * 60 * 1000,
      user_id: user.id,
      user_email: user.emailAddresses[0].emailAddress,
      user_name: `${user.firstName} ${user.lastName}`,
      jira_ticket_key: selectedJiraTicket.key,
      jira_ticket_summary: selectedJiraTicket.summary,
      jira_project_key: selectedJiraProject.key,
      jira_project_name: selectedJiraProject.name,
      created_at: new Date(),
    };

    try {
      const { data, error } = await supabase
        .from('time_entries')
        .insert([entry])
        .select()
        .single();

      if (error) throw error;

      setEntries([...entries, { ...data, date: new Date(data.date) }]);
      setNewEntry({ hours: '', minutes: '', taskDescription: '' });
      setSelectedJiraProject(null);
      setSelectedJiraTicket(null);
      
      // Regenerate chart data with fresh rates
      await generateChartData();
      
      const modalElement = document.querySelector('.modal-content');
      modalElement?.classList.add('modal-exit');
      
      setTimeout(() => {
        handleCloseModal();
        setShowSuccess(true);
      }, 200);
    } catch (error) {
      console.error('Error adding time entry:', error);
      setError(error.message);
    }
  };

  const deleteEntry = async (id) => {
    try {
      const { error } = await supabase
        .from('time_entries')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setEntries(entries.filter(entry => entry.id !== id));
    } catch (error) {
      console.error('Error deleting time entry:', error);
      setError(error.message);
    }
  };

  const formatDuration = (ms) => {
    const minutes = Math.floor(ms / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const getWeekDays = () => {
    const start = startOfWeek(selectedDate, { weekStartsOn: 1 });
    const days = [];
    
    for (let i = 0; i < 7; i++) {
      days.push(addDays(start, i));
    }
    
    return days;
  };

  const getDayEntries = (date) => {
    return entries.filter(entry => {
      const entryDate = new Date(entry.date);
      return format(entryDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
    });
  };

  useEffect(() => {
    if (entries.length > 0) {
      generateChartData();
    }
  }, [entries, timeRange]);

  const generateChartData = async () => {
    const rates = await fetchProjectRates(); // Get fresh rates
    
    let start, end, dateFormat;
    const now = new Date();

    switch (timeRange) {
      case 'week':
        start = startOfWeek(now, { weekStartsOn: 1 });
        end = now;
        dateFormat = 'EEE';
        break;
      case 'month':
        start = startOfMonth(now);
        end = now;
        dateFormat = 'MMM d';
        break;
      case 'year':
        start = startOfYear(now);
        end = now;
        dateFormat = 'MMM';
        break;
      default:
        return;
    }

    const interval = timeRange === 'year' 
      ? eachMonthOfInterval({ start, end })
      : eachDayOfInterval({ start, end });

    const data = interval.map(date => {
      let dayEntries;
      
      if (timeRange === 'year') {
        const monthStart = startOfMonth(date);
        const monthEnd = endOfMonth(date);
        dayEntries = entries.filter(entry => {
          const entryDate = new Date(entry.date);
          return entryDate >= monthStart && entryDate <= monthEnd;
        });
      } else {
        dayEntries = entries.filter(entry => {
          const entryDate = new Date(entry.date);
          return format(entryDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
        });
      }

      // Calculate total hours and revenue
      let totalHours = 0;
      let totalRevenue = 0;

      dayEntries.forEach(entry => {
        const hours = entry.duration / (1000 * 60 * 60);
        totalHours += hours;
        
        // Calculate revenue if project has a rate
        const rate = rates[entry.jira_project_key];
        if (rate) {
          totalRevenue += hours * rate;
        }
      });

      return {
        date: format(date, dateFormat),
        total: Number(totalHours.toFixed(1)),
        revenue: Number(totalRevenue.toFixed(2))
      };
    });

    console.log('Generated chart data:', data); // Debug log
    setChartData(data);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setNewEntry({ hours: '', minutes: '', taskDescription: '' });
  };

  const fetchUsers = async () => {
    try {
      // Get users from time_entries
      const { data: timeEntries, error: entriesError } = await supabase
        .from('time_entries')
        .select('user_email, user_name')
        .order('user_name');

      if (entriesError) throw entriesError;

      // Get users from app_users
      const { data: appUsers, error: usersError } = await supabase
        .from('app_users')
        .select('email, name')
        .order('name');

      if (usersError) throw usersError;

      // Combine and deduplicate users from both sources
      const allUsers = [
        ...timeEntries.map(entry => ({
          email: entry.user_email,
          name: entry.user_name
        })),
        ...appUsers.map(user => ({
          email: user.email,
          name: user.name
        }))
      ];

      // Create unique users based on email
      const uniqueUsers = Array.from(
        new Map(
          allUsers.map(user => [
            user.email,
            user
          ])
        ).values()
      );

      setUsers(uniqueUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Add useEffect to refresh users list when entries change
  useEffect(() => {
    if (isAdmin && isInitialized) {
      fetchUsers();
    }
  }, [entries, isAdmin, isInitialized]);

  // Replace the AdminControls component with a more integrated user filter
  const UserFilter = () => {
    if (!isAdmin) return null;

    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 2,
        mb: 3,
      }}>
        <Autocomplete
          value={selectedUser}
          onChange={(event, newValue) => {
            setLoading(true);
            setSelectedUser(newValue);
          }}
          options={[{ name: 'All Team Members', email: 'all' }, ...users]}
          getOptionLabel={(option) => option?.name || ''}
          sx={{ width: 250 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder={isAdmin ? "Filter by team member" : ""}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pl: 1 }}>
                    <SupervisorAccount sx={{ color: '#1976d2' }} />
                    {params.InputProps.startAdornment}
                  </Box>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box 
              component="li" 
              {...props} 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1.5,
                py: 1
              }}
            >
              {option.email === 'all' ? (
                <SupervisorAccount fontSize="small" sx={{ color: '#1976d2' }} />
              ) : (
                <Avatar 
                  sx={{ 
                    width: 24, 
                    height: 24,
                    fontSize: '0.875rem',
                    bgcolor: 'rgba(25, 118, 210, 0.1)',
                    color: '#1976d2'
                  }}
                >
                  {option.name?.[0]}
                </Avatar>
              )}
              <Typography variant="body2">{option.name}</Typography>
            </Box>
          )}
        />

        {selectedUser && selectedUser.email !== 'all' && (
          <Chip
            label={`Viewing ${selectedUser.name}'s entries`}
            onDelete={() => {
              setLoading(true);
              setSelectedUser({ name: 'All Team Members', email: 'all' });
            }}
            size="small"
            sx={{ 
              backgroundColor: 'rgba(25, 118, 210, 0.1)',
              borderRadius: '16px',
              '& .MuiChip-label': { color: '#1976d2' },
              '& .MuiChip-deleteIcon': { color: '#1976d2' }
            }}
          />
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (openModal) {
      fetchJiraProjects();
    }
  }, [openModal]);

  // Add this function before the ProjectsView component
  const processEntriesForChart = (entries, timeRange, hourlyRate) => {
    let start, end, dateFormat;
    const now = new Date();

    // Set up date range and format based on selected time range
    switch (timeRange) {
      case 'week':
        start = startOfWeek(now, { weekStartsOn: 1 });
        end = now;
        dateFormat = 'EEE';
        break;
      case 'month':
        start = startOfMonth(now);
        end = now;
        dateFormat = 'MMM d';
        break;
      case 'year':
        start = startOfYear(now);
        end = now;
        dateFormat = 'MMM';
        break;
      default:
        return [];
    }

    // Create array of dates in the range
    const interval = timeRange === 'year' 
      ? eachMonthOfInterval({ start, end })
      : eachDayOfInterval({ start, end });

    // Map entries to dates
    return interval.map(date => {
      let periodEntries;
      if (timeRange === 'year') {
        // For yearly view, group by month
        const monthStart = startOfMonth(date);
        const monthEnd = endOfMonth(date);
        periodEntries = entries.filter(entry => {
          const entryDate = new Date(entry.date);
          return entryDate >= monthStart && entryDate <= monthEnd;
        });
      } else {
        // For weekly/monthly view, group by day
        periodEntries = entries.filter(entry => 
          format(new Date(entry.date), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
        );
      }

      // Calculate total hours for the period
      const totalHours = periodEntries.reduce((sum, entry) => 
        sum + entry.duration / (1000 * 60 * 60), 0
      );

      return {
        date: format(date, dateFormat),
        total: Number(totalHours.toFixed(1)),
        hourlyRate,
        revenue: hourlyRate ? Number((totalHours * hourlyRate).toFixed(2)) : 0
      };
    });
  };

  // Update the ProjectsView component to include user management
  const ProjectsView = ({ entries, timeRange, setTimeRange, isAdmin }) => {
    const [showUserModal, setShowUserModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [projectUsers, setProjectUsers] = useState({});
    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingRate, setEditingRate] = useState(null);
    const [hourlyRate, setHourlyRate] = useState('');

    // Fetch all users for the dropdown
    const fetchAllUsers = async () => {
      setLoading(true);
      try {
        console.log('Fetching users...');
        const { data, error } = await supabase
          .from('app_users')
          .select('id, email, name, is_admin')
          .order('name');
        
        if (error) {
          console.error('Error fetching users:', error);
          throw error;
        }

        console.log('Fetched users:', data);
        setAllUsers(data || []);
      } catch (error) {
        console.error('Error in fetchAllUsers:', error);
      } finally {
        setLoading(false);
      }
    };

    // Update fetchProjectUsers to get complete user data
    const fetchProjectUsers = async (projectKey) => {
      try {
        console.log('Fetching users for project:', projectKey); // Debug log
        const { data: accessData, error: accessError } = await supabase
          .from('user_project_access')
          .select(`
            user_email,
            jira_project_key,
            app_users!user_project_access_user_email_fkey (
              name,
              email
            )
          `)
          .eq('jira_project_key', projectKey);

        if (accessError) throw accessError;

        if (accessData && accessData.length > 0) {
          const userData = accessData.map(record => record.app_users);
          console.log('Project users found:', userData); // Debug log
          setProjectUsers(prev => ({
            ...prev,
            [projectKey]: userData
          }));
        } else {
          setProjectUsers(prev => ({
            ...prev,
            [projectKey]: []
          }));
        }
      } catch (error) {
        console.error('Error fetching project users:', error);
      }
    };

    // Update addUserToProject to refresh the list after adding
    const addUserToProject = async (projectKey, userEmail) => {
      try {
        console.log('Adding user to project:', { projectKey, userEmail }); // Debug log
        const { error } = await supabase
          .from('user_project_access')
          .insert({
            jira_project_key: projectKey,
            user_email: userEmail,
            granted_at: new Date().toISOString()
          });

        if (error) {
          if (error.code === '23505') { // Unique violation error code
            console.log('User already has access to this project');
            return;
          }
          throw error;
        }

        // Refresh the project users list
        await fetchProjectUsers(projectKey);
        setShowUserModal(false);
      } catch (error) {
        console.error('Error adding user to project:', error);
      }
    };

    // Add this function to check if user has access to a project
    const checkProjectAccess = async (projectKey, userEmail) => {
      try {
        const { data, error } = await supabase
          .from('user_project_access')
          .select('*')
          .eq('project_key', projectKey)
          .eq('user_email', userEmail)
          .single();

        if (error && error.code !== 'PGRST116') { // PGRST116 is the "not found" error
          throw error;
        }

        return !!data;
      } catch (error) {
        console.error('Error checking project access:', error);
        return false;
      }
    };

    // Update the useEffect to fetch project users when component mounts
    useEffect(() => {
      const initializeProjectUsers = async () => {
        const uniqueProjects = [...new Set(entries.map(entry => entry.jira_project_key))];
        for (const projectKey of uniqueProjects) {
          await fetchProjectUsers(projectKey);
        }
      };

      if (isAdmin) {
        initializeProjectUsers();
      }
    }, [entries, isAdmin]);

    // Remove user from project
    const removeUserFromProject = async (projectKey, userEmail) => {
      try {
        const { error } = await supabase
          .from('user_project_access')
          .delete()
          .match({ 
            jira_project_key: projectKey, 
            user_email: userEmail 
          });

        if (error) throw error;
        await fetchProjectUsers(projectKey);
      } catch (error) {
        console.error('Error removing user from project:', error);
      }
    };

    // Get projects with hours for the current time range
    const getProjectData = () => {
      const projectMap = new Map();
      
      entries.forEach(entry => {
        if (!projectMap.has(entry.jira_project_key)) {
          projectMap.set(entry.jira_project_key, {
            key: entry.jira_project_key,
            name: entry.jira_project_name,
            entries: [],
            totalHours: 0
          });
        }
        const project = projectMap.get(entry.jira_project_key);
        project.entries.push(entry);
        project.totalHours += entry.duration / (1000 * 60 * 60);
      });

      return Array.from(projectMap.values())
        .filter(project => project.totalHours > 0)
        .sort((a, b) => b.totalHours - a.totalHours);
    };

    // Update the updateProjectRate function in ProjectsView
    const updateProjectRate = async (projectKey, rate) => {
      if (!projectKey || !rate) {
        console.error('Missing project key or rate');
        return;
      }

      try {
        console.log('Updating project rate:', { projectKey, rate });
        const { error } = await supabase
          .from('jira_projects')
          .update({ hourly_rate: parseFloat(rate) })
          .eq('key', projectKey);

        if (error) throw error;

        // Clear editing state
        setEditingRate(null);
        setHourlyRate('');
        
        // Refresh projects data
        const { data: updatedProjects, error: fetchError } = await supabase
          .from('jira_projects')
          .select('*')
          .order('name');
        
        if (fetchError) throw fetchError;
        
        // Update the projects in the parent component
        setJiraProjects(updatedProjects);
        
        console.log('Project rate updated successfully');
      } catch (error) {
        console.error('Error updating project rate:', error);
      }
    };

    // Update the project card to include user management
    return (
      <Box>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 3 
        }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Active Projects
          </Typography>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <Select
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.05)' }}
            >
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Grid container spacing={3}>
          {getProjectData().map((project) => (
            <Grid item xs={12} md={6} key={project.name}>
              <Paper sx={{ p: 3, borderRadius: '12px' }}>
                <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      {project.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {project.totalHours.toFixed(1)} hours this {timeRange}
                      {isAdmin && project.hourly_rate && (
                        <Typography 
                          component="span" 
                          sx={{ 
                            ml: 1,
                            color: 'success.main',
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: 0.5
                          }}
                        >
                          • ${(project.totalHours * project.hourly_rate).toFixed(2)}
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                  {isAdmin && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {editingRate === project.key ? (
                        <>
                          <TextField
                            size="small"
                            type="number"
                            value={hourlyRate}
                            onChange={(e) => setHourlyRate(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                updateProjectRate(project.key, hourlyRate);
                              }
                            }}
                            sx={{ 
                              width: '100px',
                              '& .MuiOutlinedInput-root': {
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              }
                            }}
                            InputProps={{
                              startAdornment: <Typography sx={{ mr: 0.5 }}>$</Typography>,
                            }}
                            autoFocus
                          />
                          <IconButton 
                            size="small" 
                            onClick={() => updateProjectRate(project.key, hourlyRate)}
                            sx={{ color: 'success.main' }}
                          >
                            <Check />
                          </IconButton>
                          <IconButton 
                            size="small" 
                            onClick={() => {
                              setEditingRate(null);
                              setHourlyRate('');
                            }}
                            sx={{ color: 'error.main' }}
                          >
                            <Close />
                          </IconButton>
                        </>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => {
                            setEditingRate(project.key);
                            setHourlyRate(project.hourly_rate?.toString() || '');
                          }}
                          startIcon={<AttachMoney />}
                          sx={{ 
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            }
                          }}
                        >
                          {project.hourly_rate ? `$${project.hourly_rate}/hr` : 'Set Rate'}
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>

                <Box sx={{ height: 250 }}>
                  <ResponsiveContainer>
                    <LineChart 
                      data={processEntriesForChart(project.entries, timeRange, project.hourly_rate)}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
                      <XAxis 
                        dataKey="date"
                        stroke="rgba(255, 255, 255, 0.5)"
                        tick={{ fill: 'rgba(255, 255, 255, 0.7)' }}
                      />
                      <YAxis 
                        stroke="rgba(255, 255, 255, 0.5)"
                        tick={{ fill: 'rgba(255, 255, 255, 0.7)' }}
                      />
                      <YAxis 
                        yAxisId="right"
                        orientation="right"
                        stroke="rgba(76, 175, 80, 0.5)"
                        tick={{ fill: 'rgba(76, 175, 80, 0.7)' }}
                        hide={!isAdmin || !project.hourly_rate}
                      />
                      <RechartsTooltip content={(props) => <CustomTooltip {...props} isAdmin={isAdmin} />} />
                      <Line
                        type="monotone"
                        dataKey="total"
                        stroke="#0070f3"
                        strokeWidth={2}
                        dot={{ r: 3 }}
                        activeDot={{ r: 5 }}
                      />
                      {isAdmin && project.hourly_rate > 0 && (
                        <Line
                          type="monotone"
                          dataKey="revenue"
                          stroke="#4caf50"
                          strokeWidth={2}
                          dot={{ r: 3 }}
                          activeDot={{ r: 5 }}
                          yAxisId="right"
                        />
                      )}
                    </LineChart>
                  </ResponsiveContainer>
                </Box>

                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                    Recent Entries
                  </Typography>
                  <Box sx={{ 
                    maxHeight: 200, 
                    overflowY: 'auto',
                    pr: 1,
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: '2px',
                      '&:hover': {
                        background: 'rgba(255, 255, 255, 0.2)',
                      },
                    },
                  }}>
                    {project.entries
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .slice(0, 5)
                      .map(entry => (
                        <Box
                          key={entry.id}
                          sx={{
                            p: 1.5,
                            mb: 1,
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                color: 'rgba(255, 255, 255, 0.6)',
                                display: 'block',
                                mb: 0.5,
                              }}
                            >
                              {entry.jira_project_name}
                            </Typography>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                fontWeight: 500,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                mb: 0.5,
                              }}
                            >
                              {entry.task}
                            </Typography>
                            {entry.task_description && (
                              <Typography 
                                variant="caption" 
                                sx={{ 
                                  display: 'block',
                                  color: 'rgba(255, 255, 255, 0.7)',
                                  mb: 1,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {entry.task_description}
                              </Typography>
                            )}
                            
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                opacity: 0.8,
                                justifyContent: 'flex-end',
                              }}
                            >
                              <AccessTime sx={{ fontSize: 14 }} />
                              {formatDuration(entry.duration)}
                            </Typography>
                          </Box>
                          
                          <IconButton
                            size="small"
                            onClick={() => deleteEntry(entry.id)}
                            className="delete-button"
                            sx={{ 
                              position: 'absolute',
                              right: 4,
                              top: 4,
                              opacity: 0,
                              transition: 'opacity 0.2s ease-in-out',
                              '&:hover': {
                                color: '#ff4444'
                              }
                            }}
                          >
                            <Delete sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                </Box>

                {isAdmin && (
                  <Box sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography variant="subtitle2" color="text.secondary">
                        Team Members
                      </Typography>
                      <Button
                        size="small"
                        startIcon={<Add />}
                        onClick={async () => {
                          console.log('Selected project:', project); // Debug log
                          if (!project.key) {
                            console.error('No project key found for:', project);
                            return;
                          }
                          setSelectedProject(project);
                          setShowUserModal(true);
                          await fetchAllUsers();
                          await fetchProjectUsers(project.key);
                        }}
                      >
                        Add Member
                      </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {projectUsers[project.key]?.map((user) => (
                        <Chip
                          key={user.email}
                          label={user.name}
                          onDelete={() => removeUserFromProject(project.key, user.email)}
                          sx={{ mb: 1 }}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Modal for adding users to project */}
        <Modal
          open={showUserModal}
          onClose={() => setShowUserModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
        >
          <Fade in={showUserModal}>
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: '#1a1a1a',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}>
              <Typography variant="h6" sx={{ mb: 3, color: 'white' }}>
                Add Team Member to {selectedProject?.name}
              </Typography>
              
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <Autocomplete
                  options={allUsers || []}
                  getOptionLabel={(option) => `${option.name} (${option.email})`}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      label="Select User" 
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.23)',
                          },
                          '&:hover fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.4)',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: 'rgba(255, 255, 255, 0.7)',
                        },
                      }}
                    />
                  )}
                  onChange={(_, newValue) => {
                    if (newValue && selectedProject && selectedProject.key) {
                      console.log('Adding user to project:', {
                        user: newValue,
                        project: selectedProject,
                        projectKey: selectedProject.key
                      });
                      addUserToProject(selectedProject.key, newValue.email);
                    } else {
                      console.error('Missing required data:', { newValue, selectedProject });
                    }
                  }}
                  sx={{
                    '& .MuiAutocomplete-input': {
                      color: 'white',
                    },
                  }}
                  loading={loading}
                  loadingText="Loading users..."
                  noOptionsText="No users found"
                />
              )}
            </Box>
          </Fade>
        </Modal>
      </Box>
    );
  };

  // Update the fetchProjectRates function to be more robust
  const fetchProjectRates = async () => {
    try {
      const { data, error } = await supabase
        .from('jira_projects')
        .select('key, hourly_rate')
        .not('hourly_rate', 'is', null); // Only get projects with rates set
      
      if (error) throw error;
      
      // Convert array to object for easier lookup
      const rates = {};
      data.forEach(project => {
        if (project.hourly_rate) { // Only store rates that exist
          rates[project.key] = parseFloat(project.hourly_rate);
        }
      });
      
      console.log('Fetched project rates:', rates); // Debug log
      setProjectRates(rates);
      return rates; // Return rates for immediate use
    } catch (error) {
      console.error('Error fetching project rates:', error);
      return {};
    }
  };

  // Add this function inside TimeTracker
  const filterAccessibleProjects = async (userEmail) => {
    if (!userEmail) return [];
    
    try {
      const { data, error } = await supabase
        .from('user_project_access')
        .select('jira_project_key')
        .eq('user_email', userEmail);
        
      if (error) throw error;
      
      console.log('User project access data:', data);
      return data?.map(access => access.jira_project_key) || [];
    } catch (error) {
      console.error('Error fetching accessible projects:', error);
      return [];
    }
  };

  // Update the main return statement to include ProjectsView
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#121212' }}>
        <NavigationDrawer selectedView={selectedView} setSelectedView={setSelectedView} />
        
        <Box sx={{ 
          flexGrow: 1, 
          pl: 3,
          minHeight: '100vh',
          overflowY: 'auto',
          py: 3,
          pr: 3,
        }}>
          {selectedView === 'dashboard' ? (
            // Existing dashboard content
            <>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3,
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    Time Tracker
                  </Typography>
                  {isAdmin && (
                    <Chip
                      label="Admin"
                      color="primary"
                      size="small"
                      sx={{
                        backgroundColor: 'rgba(25, 118, 210, 0.1)',
                        color: '#1976d2',
                        fontWeight: 500,
                      }}
                    />
                  )}
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 2,
                }}>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleOpenModal}
                    sx={{
                      borderRadius: '12px',
                      textTransform: 'none',
                      px: 3
                    }}
                  >
                    Add Entry
                  </Button>
                  <UserMenu user={user} signOut={signOut} />
                </Box>
              </Box>

              <UserFilter />

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 300,
                  },
                }}
              >
                <Fade in={openModal}>
                  <Box 
                    className="modal-content"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: { xs: '90%', sm: '500px' },
                      bgcolor: '#1a1a1a',
                      borderRadius: '16px',
                      boxShadow: '0 24px 40px rgba(0, 0, 0, 0.4)',
                      p: 4,
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                      transition: 'all 0.2s ease-in-out',
                      '@keyframes modalExit': {
                        '0%': {
                          opacity: 1,
                          transform: 'translate(-50%, -50%) scale(1)',
                        },
                        '100%': {
                          opacity: 0,
                          transform: 'translate(-50%, -50%) scale(0.95)',
                        },
                      },
                      '&.modal-exit': {
                        animation: 'modalExit 0.2s ease-in-out forwards',
                      },
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                      New Time Entry
                    </Typography>

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <DatePicker
                          label="Date"
                          value={selectedDate}
                          onChange={(newValue) => setSelectedDate(newValue)}
                          slotProps={{ 
                            textField: { 
                              fullWidth: true,
                              sx: {
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '12px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                }
                              }
                            } 
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={jiraProjects}
                          getOptionLabel={(option) => option.name}
                          value={selectedJiraProject}
                          onChange={(_, newValue) => {
                            setSelectedJiraProject(newValue);
                            setSelectedJiraTicket(null);
                            if (newValue) {
                              fetchJiraTickets(newValue.key);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Jira Project"
                              fullWidth
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '12px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                }
                              }}
                            />
                          )}
                          noOptionsText={isAdmin ? "No projects found" : "No accessible projects found"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={jiraTickets}
                          getOptionLabel={(option) => `${option.key} - ${option.summary}`}
                          value={selectedJiraTicket}
                          onChange={(_, newValue) => {
                            setSelectedJiraTicket(newValue);
                            if (newValue) {
                              setNewEntry(prev => ({
                                ...prev,
                                task: `${newValue.key} - ${newValue.summary}`,
                              }));
                            }
                          }}
                          loading={loadingTickets}
                          disabled={!selectedJiraProject}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Jira Ticket"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingTickets ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '12px',
                                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Task Description"
                          value={newEntry.taskDescription}
                          onChange={(e) => setNewEntry({ ...newEntry, taskDescription: e.target.value })}
                          fullWidth
                          multiline
                          rows={2}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Hours"
                          type="number"
                          value={newEntry.hours}
                          onChange={(e) => setNewEntry({ ...newEntry, hours: e.target.value })}
                          inputProps={{ min: 0, max: 24 }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Minutes"
                          type="number"
                          value={newEntry.minutes}
                          onChange={(e) => setNewEntry({ ...newEntry, minutes: e.target.value })}
                          inputProps={{ min: 0, max: 59 }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 4 }}>
                      <Button
                        variant="outlined"
                        onClick={handleCloseModal}
                        sx={{
                          borderRadius: '12px',
                          textTransform: 'none',
                          fontWeight: 600,
                          px: 3,
                          py: 1.5,
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={addTimeEntry}
                        sx={{
                          borderRadius: '12px',
                          textTransform: 'none',
                          fontWeight: 600,
                          px: 3,
                          py: 1.5,
                          backgroundColor: '#0070f3',
                          '&:hover': {
                            backgroundColor: '#0061d3',
                          }
                        }}
                      >
                        Add Entry
                      </Button>
                    </Box>
                  </Box>
                </Fade>
              </Modal>

              {showSuccess && (
                <SuccessAnimation onComplete={() => setShowSuccess(false)} />
              )}

              {error && (
                <Paper 
                  sx={{ 
                    p: 2, 
                    mb: 3, 
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    border: '1px solid rgba(255, 0, 0, 0.3)',
                    borderRadius: '8px',
                  }}
                >
                  <Typography color="error">{error}</Typography>
                </Paper>
              )}
              
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  mb: 3,
                  borderRadius: '12px',
                  backgroundColor: 'rgba(255, 255, 255, 0.03)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                }}
              >
                <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {`${calculateTotalHours(chartData).toFixed(1)} hours ${
                      timeRange === 'week' ? 'this week' : 
                      timeRange === 'month' ? 'this month' : 'this year'
                    }`}
                    {isAdmin && (
                      <Typography 
                        component="span" 
                        sx={{ 
                          ml: 1,
                          color: 'success.main',
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: 0.5
                        }}
                      >
                        • ${chartData.reduce((sum, day) => sum + (day.revenue || 0), 0).toFixed(2)}
                      </Typography>
                    )}
                  </Typography>
                  <FormControl size="small" sx={{ minWidth: 120 }}>
                    <Select
                      value={timeRange}
                      onChange={(e) => setTimeRange(e.target.value)}
                      sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        '& .MuiSelect-icon': {
                          color: 'rgba(255, 255, 255, 0.5)',
                        },
                      }}
                    >
                      <MenuItem value="week">Week</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                      <MenuItem value="year">Year</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{
                  height: 300,
                  width: '100%',
                }}>
                  <ResponsiveContainer>
                    <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
                      <XAxis 
                        dataKey="date"
                        stroke="rgba(255, 255, 255, 0.5)"
                        tick={{ fill: 'rgba(255, 255, 255, 0.7)' }}
                        interval="preserveStartEnd"
                      />
                      <YAxis 
                        stroke="rgba(255, 255, 255, 0.5)"
                        tick={{ fill: 'rgba(255, 255, 255, 0.7)' }}
                      />
                      <YAxis 
                        yAxisId="right"
                        orientation="right"
                        stroke="rgba(76, 175, 80, 0.5)"
                        tick={{ fill: 'rgba(76, 175, 80, 0.7)' }}
                        hide={!isAdmin}
                      />
                      <RechartsTooltip 
                        content={(props) => <CustomTooltip {...props} isAdmin={isAdmin} />}
                      />
                      <Line
                        type="monotone"
                        dataKey="total"
                        stroke="#fff"
                        strokeWidth={2}
                        dot={{ r: 4 }}
                        activeDot={{ r: 6 }}
                      />
                      {isAdmin && (
                        <Line
                          type="monotone"
                          dataKey="revenue"
                          stroke="#4caf50"
                          strokeWidth={2}
                          dot={{ r: 4 }}
                          activeDot={{ r: 6 }}
                          yAxisId="right"
                        />
                      )}
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>

              <Grid container spacing={2}>
                {getWeekDays().map((date) => (
                  <Grid item xs={12} sm={6} md={3} key={date.toString()}>
                    <Paper 
                      sx={{ 
                        p: 2.5,
                        borderRadius: '12px',
                        backgroundColor: isToday(date) ? 'rgba(0, 112, 243, 0.1)' : 'rgba(255, 255, 255, 0.03)',
                        border: `1px solid ${isToday(date) ? 'rgba(0, 112, 243, 0.3)' : 'rgba(255, 255, 255, 0.1)'}`,
                        height: '300px',
                        display: 'flex',
                        flexDirection: 'column',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          backgroundColor: isToday(date) ? 'rgba(0, 112, 243, 0.15)' : 'rgba(255, 255, 255, 0.05)',
                        }
                      }}
                    >
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1, 
                        pb: 2,
                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                      }}>
                        <CalendarToday 
                          sx={{ 
                            fontSize: 20,
                            color: isToday(date) ? '#0070f3' : 'inherit',
                            opacity: 0.7 
                          }} 
                        />
                        <Typography 
                          variant="subtitle1" 
                          sx={{ 
                            fontWeight: 600,
                            color: isToday(date) ? '#0070f3' : 'inherit'
                          }}
                        >
                          {format(date, 'EEE, MMM d')}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          flex: 1,
                          overflowY: 'auto',
                          mt: 2,
                          pr: 1,
                          '&::-webkit-scrollbar': {
                            width: '4px',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: 'transparent',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: '2px',
                            '&:hover': {
                              background: 'rgba(255, 255, 255, 0.2)',
                            },
                          },
                        }}
                      >
                        {getDayEntries(date).map((entry) => (
                          <Box
                            key={entry.id}
                            sx={{
                              p: 1.5,
                              mb: 1.5,
                              backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              borderRadius: '8px',
                              border: '1px solid rgba(255, 255, 255, 0.05)',
                              position: 'relative',
                              transition: 'all 0.2s ease-in-out',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                border: '1px solid rgba(255, 255, 255, 0.1)',
                                '& .delete-button': {
                                  opacity: 1,
                                },
                              },
                            }}
                          >
                            <Box sx={{ pr: 4 }}>
                              <Typography 
                                variant="caption" 
                                sx={{ 
                                  color: 'rgba(255, 255, 255, 0.6)',
                                  display: 'block',
                                  mb: 0.5,
                                }}
                              >
                                {entry.jira_project_name}
                              </Typography>
                              <Typography 
                                variant="body2" 
                                sx={{ 
                                  fontWeight: 500,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  mb: 0.5,
                                }}
                              >
                                {entry.task}
                              </Typography>
                              {entry.task_description && (
                                <Typography 
                                  variant="caption" 
                                  sx={{ 
                                    display: 'block',
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    mb: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {entry.task_description}
                                </Typography>
                              )}
                              
                              <Typography 
                                variant="caption" 
                                sx={{ 
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 0.5,
                                  opacity: 0.8,
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <AccessTime sx={{ fontSize: 14 }} />
                                {formatDuration(entry.duration)}
                              </Typography>
                            </Box>
                            
                            <IconButton
                              size="small"
                              onClick={() => deleteEntry(entry.id)}
                              className="delete-button"
                              sx={{ 
                                position: 'absolute',
                                right: 4,
                                top: 4,
                                opacity: 0,
                                transition: 'opacity 0.2s ease-in-out',
                                '&:hover': {
                                  color: '#ff4444'
                                }
                              }}
                            >
                              <Delete sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            // New projects view
            <ProjectsView 
              entries={entries} 
              timeRange={timeRange} 
              setTimeRange={setTimeRange}
              isAdmin={isAdmin}
            />
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default TimeTracker;